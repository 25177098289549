const worldvoiceSettings = {
	title: "WorldVoice 360",
	description: "A multilingual, hyper-localized news network that tailors its broadcasts to local audiences across the globe, allowing instant access to news in every spoken language. Powered by AR/VR, it offers immersive experiences that place viewers in the midst of breaking events.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'white',
	footerBg: "black",
	headerTextColor: "black",
	footerTextColor: "white",
	style: 'tabloid'
};

export default worldvoiceSettings;
